<template>
    <div class="test-page page1">
        <div class="flex items-center justify-center py-24" v-if="!dashboardLoaded">
                <Loader />
            </div>
        <div class="flex justify-end mb-2 mr-1" v-if="dashboardLoaded && dashboardOptions.length>1">
         <dropdown
                reposition
                searchable
                class="filter-dropdown h-10"
                placeholder="Dashboards"
                :options="dashboardOptions"
                v-model="selectedDashboard"
                :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#e8e8e8',
                }"
                :limit="1"
                width="192px"
                minWidth="102px"
                :config="{ label: 'name', trackBy: 'dashboard_id' }"
                @input="selectDashboard"
            >
                <template #caret>
                    <sort-icon/>
                </template>
            </dropdown>
        </div>

        <div v-if="dashboardLoaded && !dashboardUrl">
        <div class="wrapper wrapper--row card-panel scroll-bar">
        <!-- <div class="wrapper wrapper--row card-panel scroll-bar">
            <div class="flex items-center justify-center py-24 bg-base-100 rounded-xl w-full" v-if="caseCountLoader">
                <Loader />
            </div> -->
        <div
          class="flex max-w-full overflow-x-auto gap-4 lg:gap-6 card-panel scroll-bar"
        >
          <div
            class="flex items-center justify-center py-24 bg-base-100 rounded-xl w-full"
            v-if="caseCountLoader"
          >
            <Loader />
          </div>
            <template v-else>
                <card
                    @select="selectCard(card)"
                    v-for="card in caseStatusCard"
                    :key="card.label"
                    :title="card.title"
                    :value="card.value"
                    :color="card.color"
                    :class="{
                        'card--selected': selectedCard && selectedCard.id === card.id,
                    }"
                />
            </template>
        </div>
        <div class="wrapper content">
            <!-- // loader starts here  -->
            <div class="wrapper wrapper--row justify-between" v-if="cardData">
                <chart class="chart" :name="cardData.chart.name" :data="cardData.chart.data" />
                <test-table class="table" :title="caseEventDetails.name" :subtitle="cardData.table.subtitle" :data="caseEventDetails.data" colorHeader="#0D69D5" @select="handleRowClick" />
            </div>

            <div v-else class="empty-data text-lg flex bg-base-100 justify-center items-center py-32 rounded-xl text-center mt-4">
                <Loader />
            </div>
            <test-table-large
                :key="fakeData.tabs.selected"
                :handleCaseClick="handleCaseClick"
                :totalRows="candidatesTotalPage.total"
                :isLoading="isLoading"
                :sortingEnabled="true"
                :columns="cardData.tableLarge.candidateView.columns"
                :rows="cardData.tableLarge.candidateView.rows"
                :filterOptions="columnFilterOptions.candidate"
                :filterOptionsAsync="columnFilterOptionsAsync.candidate"
                @handleStatusClick="handleStatusClick"
                :pagination-options="paginationOptions"
                :filters="columnFilters.candidate"
                @page-change="onPageChange($event, 'candidate')"
                @per-page-change="onPageChange($event, 'candidate', true)"
                @column-filter="onColumnFilters($event, 'candidate')"
                @sort-change="onSortChange($event, 'candidate')"
                @search-filter="getSearchOptionsTick($event, 'candidate')"
                @filters-clear="clearFilters('candidate')"
                :config="{
                    'select-options': {
                        enabled: this.isClientWorkFlowAdmin || false,
                        selectOnCheckboxOnly: true,
                        selectionText: 'case(s) selected',
                    },
                }"
                @on-work-allocation="onWorkAllocation($event)"
                @on-case-delete="onCaseDelete($event)"
                @on-allocation="onAllocation($event)"
            >
                <template #link="{ customData }">
                  <template v-if="customData.automated">
                    <router-link
                      :to="customData.link"
                      class="text-primary whitespace-nowrap cursor-pointer hover:text-primary-focus"
                    >
                      {{ customData.case_id }}
                    </router-link>
                  </template>
                  <template v-else>
                    <div
                      @click="handleCaseClick(customData, 'case')"
                      class="text-primary whitespace-nowrap cursor-pointer hover:text-primary-focus"
                    >
                      {{ customData.case_id }}
                    </div>
                  </template>
                </template>
                <template #workAllocation>
                    <span>
                        Case Allocation
                    </span>
                </template>
            </test-table-large>
            <sidelegend v-model="isOpened" :list="cardData.sideLegend" />
        </div>

        <ModalContent ref="status-modal" name="status-modal" :title="'Status'" submitButtonText="Close" @close="handleCloseStatusModal" :showFooter="false" :width="'90%'">
            <template #content>
                <Timeline :data="timelineData" />
            </template>
        </ModalContent>
        <WorkAllocation :loadingStates="loadingStates" :allTeams="teamsStates" :allUsers="usersStates" @onWorkAllocateChange="onWorkAllocateChange($event)" @on-save="onWorkAllocationSave($event)" @getCandidateViews="getCandidateViews" @on-reset="onAllocationReset($event)" />
        <AllocationList :isLoading="loadingStates.getAllocation" :allocatedResources="allocatedResources" @removeAllocation="removeAllocation($event)" />
        </div>
        </div>
        <div class="h-screen overflow-hidden" v-if="dashboardUrl && dashboardLoaded">  
         <iframe width="100%" height="90%" :src="dashboardUrl"></iframe>
        </div>
    </div>
</template>

<script>
import card from "@shared/dashboard-components/card";
import chart from "@shared/dashboard-components/chart";
import table from "@shared/dashboard-components/table";
import tableLarge from "@shared/dashboard-components/table-large";
import sidelegend from "@shared/dashboard-components/sidelegend";
// import tabs from "@/components/dashboard-components/tabs";
import axios from "@/axios";
import { uuid } from "vue-uuid";
import ModalContent from "@/components/modal-content";
import Timeline from "@shared/timeline";
import { mapMutations, mapGetters } from "vuex";
import { DateTime } from "luxon";
import Loader from "@/components/loader";
import { onlyUnique } from "@shared/plugins/functions";
import dropdown from '@/components/dropdown-base';
import { FormatDateNew } from "@/plugins/functions";
// import { FormatDate } from "@/plugins/functions";
import {startCase} from "lodash";
import sortIcon from "@shared/assets/svg/dropdown-double.svg";

const WorkAllocation = () => import("../components/work-allocation.vue");
const AllocationList = () => import("../components/allocation-list.vue");

export default {
    name: "page1",
    components: {
        dropdown,
        card,
        chart,
        "test-table": table,
        "test-table-large": tableLarge,
        sidelegend,
        // tabs,
        ModalContent,
        Timeline,
        Loader,
        AllocationList,
        WorkAllocation,
        sortIcon,
    },
    title() {
        return `Dashboard`;
    },
    data() {
        return {
            selectedCard: null,
            sortParams:null,
            isOpened: false,
            searchLoading: {
                candidate: {},
                check: {},
            },
            searchTimeout: null,
            fakeData: {
                cards: [
                    {
                        id: 1,
                        title: "Candidates Not Started",
                        value: 150,
                        color: "#F8AA50",
                    },
                    {
                        id: 2,
                        title: "Candidates In Progress",
                        value: 800,
                        color: "#A2D413",
                    },
                    {
                        id: 3,
                        title: "Outstanding Actions",
                        value: 560,
                        color: "#029ADB",
                    },
                    {
                        id: 4,
                        title: "Alerts",
                        value: 140,
                        color: "#AE1AE2",
                    },
                    {
                        id: 5,
                        title: "Quality Control",
                        value: 100,
                        color: "#029ADB",
                    },
                ],
                tabs: {
                    selected: 1,
                    list: [
                        {
                            id: 1,
                            label: "Candidate View",
                        },
                        {
                            id: 2,
                            label: "Check View",
                        },
                    ],
                },
            },
            timelineData: {
                header: {
                    label: "Candidate Form Status",
                    icon: "#",
                },
                timeline: [],
            },
            pagination: {
                candidateView: {
                    page: 1,
                    perPage: 10,
                    total_page: 1,
                },
            },
            paginationOptions: {
                enabled: true,
                mode: "remote",
            },
            candidateViewList: [],
            candidatesTotalPage: 0,
            checkViewList: [],
            checksTotalPages: [],
            isLoading: false,
            serverParams: {
                check: {
                    page: 1,
                    perPage: 10,
                },
                candidate: {
                    page: 1,
                    perPage: 10,
                },
            },
            menuOptions: [],
            columnFilters: {
                candidate: null,
                check: null,
            },
            columnFilterOptionsAsync: {
                candidate: null,
                check: null,
            },
            caseCount: [],
            caseCountLoader: false,
            caseStatusCard: [],
            caseEventDetails: {},
            allStatusOptions: [],
            allTeams: [],
            allUsers: [],
            loadingStates: {
                teams: false,
                users: false,
                getAllocation: false,
            },
            teamsStates: [],
            usersStates: [],
            allocatedResources: [],
            client_id: null,
            dashboardUrl:'',
            dashboardLoaded: false,
            dashboardOptions: [],
            selectedDashboard: null,
        };
    },
    created() {
        // TODO change to selection of the first card from API:
        // this.selectCard(this.fakeData.cards[0]);
    },
    async mounted() {
        await this.getTenantDashboards()
        if (this.dashboardOptions.length == 0) {
            this.$toast.error("No dashboard assigned to tenant.")
        }
        else {
            if (this.selectedDashboard?.name != 'Default Dashboard') {
                this.$router.push({
                    name: "Bi-Dashboard-View",
                    params: {
                      id: this.selectedDashboard.dashboard_id,
                    },
                });
            } 
            if (this.selectedDashboard?.dashboard_id == process.env.VUE_APP_DEFAULT_DASHBOARD_KEY) {
                this.dashboardLoaded = false
                await this.fetchDashboardData()
                this.dashboardLoaded = true
            }
            // else {
            //     this.dashboardLoaded = false
            //     await this.getQsDashboardUrl()
            // }
        }

        const list = await this.getCaseStatusCount();
        if (!this.$store.getters.getClientId) {
            await this.$store.dispatch("fetchClientId");
        }
        this.client_id = this.$store.getters.getClientId;
        const checks = await this.getCheckLists();
        const status = await this.getCaseStatus();
        Promise.allSettled([list, checks, status]).then((results) => {
            console.log("results :>> ", results);
        });

        if (this.caseStatusCard.length) this.selectCard(this.caseStatusCard[0]);
        await this.getCandidateViews();
        await this.fetchAllStatusOptions();
        await this.fetchAllUsers();
        await this.fetchAllTeams();
        const title = "Dashboard";
        let tenant = {
            // domain_name: "Neotas",
            ...{ 
            domain_name: this.$store?.getters?.getClientName || this.$store?.getters?.getLoginTenantDetails?.domain_name,
            logo_url: this.$store?.getters?.getThemeLogo || this.$store?.getters?.getLoginTenantDetails?.logo_url,
            },
        }
        if (title) {
            document.title = title;
            if (tenant?.domain_name) 
            document.title += ' | ' + startCase(tenant.domain_name);
        } else {
            if (tenant?.domain_name) 
            document.title = startCase(tenant.domain_name);
        }
        if (tenant?.logo_url) document.querySelector("link[rel~='icon']").href = tenant?.logo_url;
        else if (this.getLoginScreenInfo) {
            if (process.env.VUE_APP_TAG_NAME.includes("lightray"))
                document.querySelector("link[rel~='icon']").href = `${process.env.VUE_APP_API_BASE_URL}/lightray_favicon.ico`;
            else 
                document.querySelector("link[rel~='icon']").href = `${process.env.VUE_APP_API_BASE_URL}/delvium_favicon.ico`;
        }
    },
    computed: {
        ...mapGetters(["getLoginScreenInfo"]),
        cardData() {
            if (!this.selectedCard) return null;
            return {
                chart: {
                    name: "Some data",
                    data: this.caseCount.map((el) => ({
                        name: el.status_name,
                        value: el.cases_count,
                        color: el.status_color,
                    })),
                },
                table: {
                    title: this.selectedCard.title,
                    subtitle: "Entity information",
                    data: [],
                },
                tableLarge: {
                    candidateView: {
                        columns: [
                            {
                                label: "Case ID",
                                field: "case_id",
                                thClass: "nowrap",
                                tdClass: "text-center",
                                config: {
                                    type: "link",
                                },
                            },
                            {
                                label: "Client",
                                field: "client_name",
                                tdClass: "text-center capitalize",
                            },
                            {
                                label: "Requestor",
                                field: "created_by_name",
                                query_key: "created_by",
                            },
                            {
                                label: "Invitation Date",
                                field: "created_at",
                                query_key: "from_created_at",
                            },
                            {
                                label: "Case Deadline",
                                field: "due_date",
                                query_key: "from_due_date",
                            },
                            {
                                label: "Allocated Group",
                                field: "teams",
                                query_key: "client_teams",
                                config: {
                                    type: "team_allocation",
                                    units: "groups",
                                    isAllowed: this.isClientWorkFlowAdmin? this.isClientWorkFlowAdmin: true,
                                    // filterOptions: {
                                    //   enabled: false, // enable filter for this column
                                    // },
                                },
                                prefetchedOptions: this.allTeams.map((team) => ({ ...team, name: team.title })),
                            },
                            {
                                label: "Allocated Users",
                                field: "users",
                                query_key: "client_users",
                                config: {
                                    type: "user_allocation",
                                    units: "users",
                                    isAllowed: this.isClientWorkFlowAdmin? this.isClientWorkFlowAdmin: true,
                                    // filterOptions: {
                                    //   enabled: false, // enable filter for this column
                                    // },
                                },
                                prefetchedOptions: this.allUsers.map((user) => ({ ...user, name: user.user_name })),
                            },
                            {
                                label: "Subject form Status",
                                field: "form_status",
                            },
                            {
                                label: "First Name",
                                field: "candidate_first_name",
                                query_key: "first_name",
                                sortable: true,
                                config: {
                                    sortable_key: "candidate_first_name",
                                },
                            },
                            {
                                label: "Surname",
                                field: "candidate_last_name",
                                query_key: "last_name",
                                sortable: true,
                                config: {
                                    sortable_key: "candidate_last_name",
                                },
                            },
                            {
                                label: "Package",
                                field: "screening_package_name",
                                tdClass: "text-center uppercase",
                                query_key: "package",
                                sortable: true,
                                config: {
                                sortable_key: "screening_package_name",
                                },
                            },
                            {
                                label: "Education Check",
                                field: "education_references",
                                config: {
                                    type: "indicators",
                                    // icon: 'https://cdn.sstatic.net/Img/favicons-sprite16.png?v=03de105488a8',
                                },
                            },
                            {
                                label: "Employment Check",
                                field: "employment_references",
                                config: {
                                    type: "indicators",
                                    // icon: 'https://cdn.sstatic.net/Img/favicons-sprite16.png?v=03de105488a8',
                                },
                            },
                            {
                                label: "Checks",
                                field: "other_references",
                                config: {
                                    type: "indicators",
                                    // icon: 'https://cdn.sstatic.net/Img/favicons-sprite16.png?v=03de105488a8',
                                },
                            },
                            {
                                label: "Case Status",
                                field: "case_status",
                                tdClass: "vgt-center-align",
                                config: {
                                    type: "status",
                                },
                                query_key: "case_status",
                                sortable: false,
                                prefetchedOptions: this.allStatusOptions,
                            },
                            {
                                label: "Phone",
                                field: "candidate_phone",
                                query_key: "candidate_phone",
                                sortable: true,
                                config: {
                                    sortable_key: "candidate_phone",
                                },
                            },
                            {
                                label: "Email",
                                field: "candidate_email",
                                query_key: "candidate_email",
                                sortable: true,
                                config: {
                                sortable_key: "candidate_email",
                                },
                            },
                        ]
                            // add isLoading prop for columns:
                            .map((el) => ({
                                ...el,
                                config: {
                                    ...el.config,
                                    isLoading: !!this.searchLoading.candidate[el.field],
                                },
                            })),
                        // rows: this.candidateViewList,
                        rows: this.filteredData.candidateList || [],
                    },
                    checkView: {
                        columns: [
                            {
                                label: "Case ID",
                                field: "case_id",
                                thClass: "nowrap",
                                config: {
                                    type: "link",
                                },
                                filterOptions: {
                                    styleClass: "class1", // class to be added to the parent th element
                                    enabled: true, // enable filter for this column
                                },
                            },
                            {
                                label: "Client",
                                field: "client_name",
                                query_key: "client",
                                sortable: true,
                                config: {
                                    sortable_key: "client_name",
                                },
                            },
                            {
                                label: "Requestor",
                                field: "created_by_name",
                                query_key: "created_by",
                                sortable: true,
                                config: {
                                    sortable_key: "created_by_name",
                                },
                            },
                            {
                                label: "Invitation Date",
                                field: "created_at",
                                formatFn: this.formatFn,
                                query_key: "from_created_at",
                                sortable: true,
                                field_type: "date",
                                config: {
                                    sortable_key: "created_at",
                                },
                            },
                            {
                                label: "Case Deadline",
                                field: "due_date",
                                formatFn: this.formatFn,
                                query_key: "from_due_date",
                                sortable: true,
                                field_type: "date",
                                config: {
                                sortable_key: "due_date",
                                },
                            },
                            {
                                label: "Package",
                                field: "screening_package_name",
                                query_key: "package",
                                
                                sortable: true,
                                config: {
                                sortable_key: "screening_package_name",
                                },

                            },
                            {
                                label: "Check",
                                field: "check_title",
                                query_key: "check",
                                sortable: true,
                                config: {
                                sortable_key: "check_title",
                                },
                            },
                            {
                                label: "Alert",
                                field: "alert",
                                query_key: "alert",
                                sortable: false,
                            },
                            {
                                label: "First Name",
                                field: "candidate_first_name",
                                query_key: "first_name",
                                sortable: true,
                                config: {
                                    sortable_key: "candidate_first_name",
                                },
                            },
                            {
                                label: "Surname",
                                field: "candidate_last_name",
                                query_key: "last_name",
                                sortable: true,
                                config: {
                                sortable_key: "candidate_last_name",
                                },
                            },
                            // {
                            //   label: "Referee Email ID",
                            //   field: "referee_email",
                            // },
                            // {
                            //   label: "Referee Phone",
                            //   field: "referee_phone",
                            // },
                        ]
                            // add isLoading prop for columns:
                            .map((el) => ({
                                ...el,
                                config: {
                                    ...el.config,
                                    isLoading: !!this.searchLoading.check[el.field],
                                },
                            })),
                        rows: this.filteredData.checkViewList || [],
                    },
                },
                sideLegend: [
                    { label: "Not Started", color: "#969FAA" },
                    { label: "Started", color: "#05BEF9" },
                    { label: "In Progress", color: "#05BEF9" },
                    { label: "Action Needed", color: "#F0AD00" },
                    { label: "On Hold", color: "#F0AD00" },
                    { label: "Past SLA", color: "#F81A77" },
                    { label: "Complete", color: "#57B54F" },
                    { label: "Flagged", color: "#FF0000" },
                ],
            };
        },
        filteredData() {
            return {
                candidateList: this.candidateViewList,
                checksList: this.checkViewList,
            };
        },
        columnFilterOptions() {
            return {
                check: this.getFilterOptions(this.cardData.tableLarge.checkView.columns, this.checkViewList),
                candidate: this.getFilterOptions(this.cardData.tableLarge.candidateView.columns, this.candidateViewList),
            };
        },
        isClientWorkFlowAdmin() {
            return this.$store.getters.getIsClientWorkFlowAdmin;
        },
    },
    methods: {
        FormatDateNew,
        ...mapMutations(["SET_CASE_DETAILS"]),
        async fetchDashboardData() {
            const list = await this.getCaseStatusCount();
            if (!this.$store.getters.getClientId) {
                await this.$store.dispatch("fetchClientId");
            }
            this.client_id = this.$store.getters.getClientId;
            const checks = await this.getCheckLists();
            const status = await this.getCaseStatus();
            Promise.allSettled([list, checks, status]).then((results) => {
                console.log("results :>> ", results);
            });

            if (this.caseStatusCard.length) this.selectCard(this.caseStatusCard[0]);
            await this.getCandidateViews();
            await this.fetchAllStatusOptions();
            await this.fetchAllUsers();
            await this.fetchAllTeams();
        },

        async selectDashboard() {
            if (this.selectedDashboard?.name != 'Default Dashboard') {
                this.$router.push({
                    name: "Bi-Dashboard-View",
                    params: {
                      id: this.selectedDashboard.dashboard_id,
                    },
                });
            } else {
                if (this.selectedDashboard?.dashboard_id != process.env.VUE_APP_DEFAULT_DASHBOARD_KEY) {
                    this.dashboardLoaded = false
                    await this.getQsDashboardUrl()
                }
                else if (this.selectedDashboard?.dashboard_id == process.env.VUE_APP_DEFAULT_DASHBOARD_KEY) {
                    this.dashboardLoaded = false
                    await this.fetchDashboardData()
                    this.dashboardLoaded = true
                    this.dashboardUrl = ''
                }
            }
        },

        async getTenantDashboards() {
            try {
                const {data} = await axios.get('/dashboard/client/assigned-dashboards')
                this.dashboardOptions = data.dashboards
                this.selectedDashboard = this.dashboardOptions?.[0]
            }
            catch(err) {
                console.log(err)
            }
        },

        async getQsDashboardUrl() {
            try {
                const {data} = await axios.get(`/dashboard/client/qs-dashboard/${this.selectedDashboard.dashboard_id}`)
                this.dashboardLoaded = true
                this.dashboardUrl = data.url
                return data.url
            }
            catch(err) {
                this.dashboardLoaded = false
                return ''
            }
        },
        selectCard(card) {
            this.selectedCard = card;
            this.getCaseStatusDetails(card.id);
        },
        selectTab(id) {
            this.fakeData.tabs.selected = id;
        },

        async getCandidateViews(params = null) {
            this.isLoading = true;
            let qParams = new URLSearchParams();
            let filterParams = {};

            const getFilterValue = (key) => {
                if (["form_status", "case_status", "client_teams", "client_users"].includes(key)) {
                    if(key === "form_status"){
                        let filterData = this.candidateViewList.find(item => item.form_status === this.columnFilters.candidate[key]["name"]);
                        this.columnFilters.candidate[key]["form_status_id"] = filterData.form_status_id;
                        return this.columnFilters.candidate[key]["form_status_id"]
                    }
                    return this.columnFilters.candidate[key]["id"];
                } else return this.columnFilters.candidate[key]["name"];
            };

            if (this.columnFilters.candidate){
                Object.keys(this.columnFilters.candidate).map((key) => {
                    // if that key exist
                    // add it to filter else we'll delete it
                    // so it wont make empty strings in parameters
                    filterParams[key] = this.columnFilters.candidate[key] ? getFilterValue(key) : delete filterParams[key];
                });
                if(this.candidateViewParams && this.candidateViewParams?.events)
                 qParams.append("events",this.candidateViewParams?.events[0])
            }
            if (params) {
                Object.keys(params).forEach((key) => {
                    // if (Array.isArray(params[key])) {
                    params[key].map((ele) => {
                        qParams.append(key, ele);
                    });
                    // } else qParams.set(key, params[key])
                });
            }
            let { data, error } = await axios(`/dashboard/client?${qParams}`, {
                params: {
                    ...filterParams,
                    ...this.sortParams,
                    page: this.serverParams.candidate.page,
                    count: this.serverParams.candidate.perPage,
                },
            });
            if (error) {
                console.log(error, "getCandidateViews ");
            } else {
                let finalViewData = []
                // const FormateDateRow = (date) => date ? DateTime.fromISO(date).setZone("UTC").toFormat("dd MMM yyyy") : "";
                // const FormateDateRow = (date) => date ? DateTime.fromFormat(date, "dd MMM yyyy").plus({ days: 0 }).toISODate() : '';
                data.data?.map(el => {
                    finalViewData?.push({
                        ...el,
                        created_at: this.FormatDateNew(new Date(el.created_at)),
                        due_date: el.due_date === null? "" : this.FormatDateNew(new Date(el.due_date)),
                    })
                })
                this.candidateViewList = finalViewData;
                this.candidatesTotalPage = data.page;
            }
            this.isLoading = false;
            // console.log(this.candidateViewList, "candidate List");
        },

        async getCheckLists(params = null) {
            this.isLoadingChecks = true;
            let filterParams = {};
            let qParams = new URLSearchParams();
            const getFilterValue = (key) => {
                if (["form_status", "case_status"].includes(key)) {
                    return this.columnFilters.check[key]["id"];
                } else return this.columnFilters.check[key]["name"];
            };
            if (params) {
                Object.keys(params).forEach((key) => {
                    // if (Array.isArray(params[key])) {
                    params[key].map((ele) => {
                        qParams.append(key, ele);
                    });
                    // } else qParams.set(key, params[key])
                });
            }
            if (this.columnFilters.check)
                Object.keys(this.columnFilters.check).map((key) => {
                    // if that key exist
                    // add it to filter else we'll delete it
                    // so it wont make empty strings in parameters
                    filterParams[key] = this.columnFilters.check[key] ? getFilterValue(key) : delete filterParams[key];
                });

            try {
                // const { data } = await axios(`/dashboard/checks/client?${qParams}`, {
                //   params: {
                //     ...filterParams,
                //     page: this.serverParams.check.page,
                //     count: this.serverParams.check.perPage,
                //   },
                // });
                // const formateDate = (date) => DateTime.fromISO(date).toFormat('yyyy-LL-dd')
                // this.checkViewList = data?.data.map(el => ({...el, created_at: formateDate(el.created_at), due_date: formateDate(el.due_date)}));
                // this.checkViewList = data.data;
                // this.checksTotalPages = data.page;
            } catch (error) {
                console.log("error.message :>> ", error.message);
            }
            this.isLoadingChecks = false;
        },
        // handle link type cell => click
        // async handleCaseClick(caseId) {
        //   console.log(caseId);
        // },
        /// triggers when row is clicked ==>
        async handleRowClick({ id, value }) {
            this.candidateViewParams = {};
            this.caseEventDetails.data = this.caseEventDetails.data.map((el) => (el.id === id ? { ...el, selected: value } : el));
            // row is checked
            const key = "events";

            this.caseEventDetails.data.forEach((card) => {
                if (card.selected) {
                    if (this.candidateViewParams[key]) this.candidateViewParams[key] = [...this.candidateViewParams[key], card["id"]];
                    else this.candidateViewParams[key] = [card["id"]];
                }
            });
            // // row is unchecked
            // this.getCandidateViews(this.candidateViewParams);
            const conf = {
                candidate: this.getCandidateViews,
                check: this.getCheckLists,
            };
            Object.keys(conf).forEach(async (type) => {
                this.serverParams[type].page = 1;
                await conf[type](this.candidateViewParams);
            });
        },
        async clearFilters(type) {
            this.columnFilters[type] = null;
            const conf = {
                candidate: this.getCandidateViews,
                check: this.getCheckLists,
            };
            await conf[type](this.candidateViewParams);
        },
        async getCaseStatusCount() {
            try {
                const { data } = await axios(`dashboard/client-case-status-count`);
                this.caseCount = data?.data;
            } catch (error) {
                console.log("error :>> ", error);
            }
        },

        async fetchAllStatusOptions() {
            try {
                const response = await axios(`case/status`);
                this.allStatusOptions = response.data.data?.case_status;
            } catch (error) {
                console.log("error :>> ", error);
            }
        },

        async handleStatusClick(data) {
            const case_id = data.row.id;
            try {
                const { data } = await axios(`case/${case_id}/status-timeline`);
                // console.log('data :>> ', data);
                //  date: new Date("2022.01.02"),
                //     title: "Invitation Sent",
                //     description: "Alert 1",
                //     color: "blue",
                //     icon: "#"
                if (data)
                    this.timelineData.timeline = data.data.map((el) => ({
                        date: new Date(el.timestamp),
                        title: el.status_name,
                        description: "",
                        color: el.status_color,
                    }));
            } catch (error) {
                console.log("error :>> ", error);
            }
            this.$refs["status-modal"].showModal();
        },

        handleCloseStatusModal() {
            this.$refs["status-modal"].hideModal();
        },
        async handleCaseClick(caseData) {
            if (caseData && caseData.case_id) {
                // this.SET_CASE_DETAILS(caseData);
                const route = this.$router.resolve({
                    name: "Profile Container",
                    params: { caseid: caseData.case_id },
                    query: { caseid: caseData.id || caseData.case_uuid },
                });
                if (route) window.open(route.href);
            }
        },
        formatFn: (value) => {
            // console.log(value);
            if (value) value = DateTime.fromISO(value).toFormat("EEEE, dd LLLL yyyy");
            else value = "-";
            console.log(value);
            return value;
        },

        async onSortChange(data, type) {
            const conf = {
                candidate: this.getCandidateViews,
                check: this.getCheckLists,
                // task: this.getTaskView,
            };
            if (data && data.length && data[0].type !== 'none') {
                data = data[0]
                this.sortParams = {
                    order_key: this.checkSortableKey(data.field, type),
                    order_type: data.type,
                },
                await conf[type](this.candidateViewParams)
            } else {
                this.sortParams = null
                await conf[type](this.candidateViewParams)
            }

        },
        checkSortableKey(field, type) {
            const columns = {
                check: this.cardData.tableLarge.checkView.columns,
                candidate: this.cardData.tableLarge.candidateView.columns,
                // task: this.cardData.tableLargetaskView.columns,
            };
            return columns[type].find(el =>( (el.field === field) && el.sortable))?.config.sortable_key
        },

        onPageChange(data, type, resetPage = false) {
            if (resetPage) {
                this.serverParams[type].page = 1;
            }
            if (type) {
                if (!resetPage) this.serverParams[type].page = data.currentPage;
                this.serverParams[type].perPage = data.currentPerPage;
                try {
                    // type === "check" ? this.getCheckLists() : this.getCandidateViews();
                    type === "check" ? this.getCheckLists(this.candidateViewParams) : this.getCandidateViews(this.candidateViewParams);
                } catch (error) {
                    console.log("error fetching table query :>> ", error);
                }
            }
        },
        async onColumnFilters({ columnFilters }, type) {
            // console.warn('onColumnFilters', columnFilters);
            // Object.keys(columnFilters).forEach((key) => {
            //   if (!columnFilters[key]?.length > 0) delete columnFilters[key];
            // });
            this.columnFilters[type] = columnFilters ? { ...columnFilters } : null;
            const conf = {
                candidate: this.getCandidateViews,
                check: this.getCheckLists,
            };
            await conf[type]();
        },

        getFilterOptions(columns, rows) {
            return columns.reduce((result, curr) => {
                result[curr.field] = rows
                    .filter((row) => row[curr.field])
                    .map((row) => row[curr.field])
                    .filter(onlyUnique)
                    .map((el) => ({ id: el.form_status_id || uuid.v4(), name: el }));
                return result;
            }, {});
        },

        async getCaseStatus() {
            this.caseCountLoader = true;
            try {
                const { data } = await axios(`dashboard/client-events`);
                this.caseStatusCard = data?.data.map((el) => ({
                    title: el.name,
                    color: el.color,
                    id: el.id,
                    value: el.value,
                }));
            } catch (error) {
                console.log("error :>> ", error);
            }
            this.caseCountLoader = false;
        },

        async getCaseStatusDetails(event_id) {
            try {
                const { data } = await axios(`dashboard/client-events/${event_id}`);
                this.caseEventDetails = data.data;
                this.caseEventDetails.data = this.caseEventDetails.data.map((el) => ({
                    ...el,
                    label: el.label,
                    value: el.value,
                    // selected: true,
                }));
            } catch (error) {
                console.log("error :>> ", error);
            }
        },

        // delay for searching:
        getSearchOptionsTick(payload, type) {
            if (this.searchTimeout) clearTimeout(this.searchTimeout);
            const delay = 500;
            this.searchTimeout = setTimeout(() => {
                this.getSearchOptions(payload, type);
            }, delay);
        },

        async getSearchOptions(payload, type) {
            // if searching is canceled reset async filter options for the field:
            if (!payload.value) this.$set(this.columnFilterOptionsAsync, type, null);
            else {
                // set 'loading' prop for the column we are searching in:
                this.$set(this.searchLoading[type], payload.column.field, true);
                try {
                    const requestData = {
                        ...this.columnFilters[type],
                        [payload.column.field || payload.column.query_key]: payload.value,
                    };
                    const { data } = await axios.get(`dashboard/client`, {
                        params: requestData,
                    });
                    // set async options:
                    const columns = {
                        check: this.cardData.tableLarge.checkView.columns,
                        candidate: this.cardData.tableLarge.candidateView.columns,
                    };
                    this.$set(this.columnFilterOptionsAsync, type, this.getFilterOptions(columns[type], data.data));
                } catch (error) {
                    console.log("error :>> ", error);
                }

                this.$set(this.searchLoading[type], payload.column.field, false);
            }
        },

        // Work allocation
        async onWorkAllocation(rows) {
            this.usersStates = [];
            this.teamsStates = [];
            // this.fetchAllTeams();
            // this.fetchAllUsers();
            this.fetchAllocatedStates("users", rows);
            this.fetchAllocatedStates("teams", rows);
            this.$modal.show("work-allocation", { rows });
        },
        async fetchAllocatedStates(type, rows) {
            let url = "";
            const payload = {
                case_ids: rows.map((row) => ({ id: row.id })),
            };
            this.loadingStates[type] = true;
            if (type === "users") {
                url = "/client-case-allocation/case/user-state";
            } else {
                this.loadingStates.teams = true;
                url = "/client-case-allocation/case/group-state";
            }
            try {
                const { data } = await axios.post(url, payload);
                this[`${type}States`] = data?.map((res) => ({ ...res, status: null, id: type === "users" ? res.id : res.group_id }));
            } catch (error) {
                console.log(error, "[+] error while fetching teams");
            }
            this.loadingStates[type] = false;
        },

        async fetchAllTeams() {
            this.loadingStates.teams = true;
            try {
                const { data } = await axios.get(`group/client/${this.client_id}`);
                this.allTeams = data.map((team) => ({ ...team, status: null }));
            } catch (error) {
                console.log(error, "[+] error while fetching teams");
            }
            this.loadingStates.teams = false;
        },
        async fetchAllUsers() {
            this.loadingStates.users = true;
            try {
                const { data } = await axios.get(`user/client/${this.client_id}`);
                this.allUsers = data.map((user) => ({ ...user, status: null }));
            } catch (error) {
                console.log(error, "[+] error while fetching users");
            }
            this.loadingStates.users = false;
        },
        onWorkAllocateChange({ index, value, type }) {
            this[type][index].status = this[type][index].status === value ? null : value;
        },
        async onWorkAllocationSave({ cases, type }) {
            //type: team or user
            let url = "";
            let payload = {
                case_ids: cases.map((cas) => ({ id: cas.id })),
            };
            console.log(this.teamsStates, "team states");
            if (type === "team") {
                url = "/client-case-allocation/case/group";
                payload.add_groups = this.teamsStates.filter((user) => user.status === "add").map((elem) => elem.id);
                payload.remove_groups = this.teamsStates.filter((user) => user.status === "remove").map((elem) => elem.id);
            } else {
                url = "/client-case-allocation/case/user";
                payload.add_users = this.usersStates.filter((user) => user.status === "add").map((elem) => elem.id);
                payload.remove_users = this.usersStates.filter((user) => user.status === "remove").map((elem) => elem.id);
            }
            try {
                await axios.post(url, payload);
                this.$toast.success("Saved Successfully!");
            } catch (error) {
                this.$toast.error("Saving Failed!");
            }
        },
        async fetchAllocationList(caseId, type) {
            console.log(caseId, "case id ");
            this.loadingStates.getAllocation = true;
            let payload = {
                case_ids: [],
            };
            payload.case_ids.push({ id: caseId });
            const url = `/client-case-allocation/case/${type}-state`;
            try {
                const { data } = await axios.post(url, payload);
                this.allocatedResources = data?.filter((res) => res.allotted) ?? [];
            } catch (error) {
                console.log(error, "[+] error while fetch allocation list");
            }
            this.loadingStates.getAllocation = false;
        },
        async onAllocation({ row, type }) {
            let types = {
                groups: "group",
                users: "user",
            };
            this.allocatedResources = [];
            this.$modal.show("allocation-list", { type, row });
            await this.fetchAllocationList(row.id, types[type]);
        },
        async removeAllocation({ resource, type, row }) {
            let types = {
                groups: "group",
                users: "user",
            };
            console.log(resource, "ASdasd");
            this.loadingStates.getAllocation = true;
            let url = "";
            let payload = {
                case_ids: [{ id: row.id }],
            };
            if (type === "groups") {
                url = "/client-case-allocation/case/group";
                payload.add_groups = [];
                payload.remove_groups = [resource.id || resource.group_id];
            } else {
                url = "/client-case-allocation/case/user";
                payload.add_users = [];
                payload.remove_users = [resource.id || resource.group_id];
            }
            try {
                await axios.post(url, payload);
                await this.fetchAllocationList(row.id, types[type]);
                this.getCandidateViews();
                this.$toast.success("Removed Successfully!");
            } catch (error) {
                this.$toast.error("Removing Failed!");
            }
            this.loadingStates.getAllocation = false;
        },
        async onAllocationReset({ type, rows }) {
            await this.fetchAllocatedStates(type, rows);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/mixins/mixins.scss";
::v-deep {
    .button-base {
        @include buttonBase(#fff, #029adb, #029adb);
    }
}

.page1 {
    gap: 0;
    .card-panel {
        padding-bottom: 15px;
        .card {
            &--selected {
                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    bottom: -15px;
                    width: 0;
                    height: 0;
                    border-left: 26px solid transparent;
                    border-right: 26px solid transparent;
                    border-bottom: 28px solid var(--theme-card-background);
                    box-sizing: border-box;
                    transform: translateX(-50%);
                    left: 50%;
                }
            }
        }
    }
    .content {
        // background-color: #fff;
        @apply bg-card-bg;
        padding: 40px;
        width: 100%;
        border-radius: 24px;
        .chart {
            height: 375px;
        }
        .chart,
        .table {
            flex: 1 0 40%;
        }
    }
}
</style>
